.home {
  position: relative;
  height: 80vh;
  overflow: hidden;
}
.welcome {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  font-size: 24px;
  text-align: center;
  color: #999;
}
