.login-form {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.login-page {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.login-page .login-form {
  width: 300px;
  height: 400px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.login-page .login-form .login-form-row {
  color: #1890ff;
  height: 80px;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}
.login-form-button {
  width: 100%;
}
